import BaseEntityModelFilter from "./../general/BaseEntityModelFilter";
import store from "./../../store";

export default class PlacesFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.activationTypeTokens =
      store.getters.generalSetting.places.activationTypeTokens || [];
    this.systemComponentToken = "";
    this.systemComponentHierarchyToken = "";
    this.parentPlaceToken = "";
    this.geographicalDistributionDataInclude = false;
    this.systemComponentDataInclude = false;
    this.systemComponentsHierarchyDataInclude = false;
    this.parentPlaceDataInclude = false;
    this.getOnlyParentPlaces = false;
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.systemComponentToken = data.systemComponentToken ?? "";
      this.systemComponentHierarchyToken =
        data.systemComponentHierarchyToken ?? "";
      this.parentPlaceToken = data.parentPlaceToken ?? "";
      this.geographicalDistributionDataInclude =
        data.geographicalDistributionDataInclude ?? false;
      this.systemComponentDataInclude =
        data.systemComponentDataInclude ?? false;
      this.systemComponentsHierarchyDataInclude =
        data.systemComponentsHierarchyDataInclude ?? false;
      this.parentPlaceDataInclude = data.parentPlaceDataInclude ?? false;
      this.getOnlyParentPlaces = data.getOnlyParentPlaces ?? false;
    } else {
      this.setInitialValue();
    }
  }
}
