import { render, staticRenderFns } from "./Newss.vue?vue&type=template&id=aa7ba492&"
import script from "./Newss.vue?vue&type=script&lang=js&"
export * from "./Newss.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports